<script>
export default {
  props: {
    color: {
      type: String,
      required: false,
      default: 'currentColor',
    },
    size: {
      type: [String, Number],
      default: '20',
    },
  },
  computed: {
    pathSource() {
      // To support icons with multiple paths
      const path = this.icons[`${this.icon}-${this.type}`];
      if (path.constructor === Array) {
        return path;
      }
      return [path];
    },
  },
};
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :fill="color"
    viewBox="0 0 256 256"
  >
    <path
      d="M136,72a8,8,0,0,1,8-8h52.69L178.34,45.66a8,8,0,0,1,11.32-11.32l32,32a8,8,0,0,1,0,11.32l-32,32a8,8,0,0,1-11.32-11.32L196.69,80H144A8,8,0,0,1,136,72Zm87.87,111.08A56.24,56.24,0,0,1,168,232C88.6,232,24,167.4,24,88A56.24,56.24,0,0,1,72.92,32.13a16,16,0,0,1,16.62,9.51l21.12,47.15,0,.12A16,16,0,0,1,109.39,104c-.18.27-.37.52-.57.77L88,129.45c7.49,15.22,23.41,31,38.83,38.51l24.34-20.71a8.12,8.12,0,0,1,.75-.56,16,16,0,0,1,15.18-1.4l.11.06,47.11,21.11A16,16,0,0,1,223.87,183.08Zm-15.87-2s-.07,0-.11,0h0l-47-21.05-24.35,20.71a8,8,0,0,1-.74.56,16,16,0,0,1-15.75,1.14c-18.73-9-37.4-27.58-46.46-46.11a16,16,0,0,1,1-15.7,7,7,0,0,1,.57-.77L96,95.15l-21-47a.61.61,0,0,1,0-.12A40.2,40.2,0,0,0,40,88,128.14,128.14,0,0,0,168,216,40.21,40.21,0,0,0,208,181.07Z"
    />
  </svg>
</template>
